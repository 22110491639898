import React, { useState } from "react"
import { Link } from 'gatsby';

const RenderLink = (link) => {
    const externalLink = link.target.substring(0,4) === "http";
    if(externalLink) {
        return(<a href={link.target}>{link.title}</a>);
    }
    return (<Link to={link.target}>{link.title}</Link>);
}

const FooterNavigationBlock = ({ heading, links}) => {

    const [accordionOpen, setAccordionOpen] = useState(false);

    return (
        <div className="w-full py-2 mr-16 lg:py-0 lg:w-auto">
            <button aria-label={heading} className="flex flex-row justify-between w-full" onClick={() => setAccordionOpen((o) => !o)}>
                <h2 className="font-bold">{heading}</h2>
                <div className="lg:hidden">
                    <svg className={`${!accordionOpen ? 'block' : 'hidden'} h-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                    <svg className={`${accordionOpen ? 'block' : 'hidden'} h-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </button>
            <ul className={`${accordionOpen ? 'block' : 'hidden'} mt-4 lg:block`}>
                {links.map(function(link, i){
                    return (
                        <li key={i.toString()}>
                            {RenderLink(link)}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default FooterNavigationBlock;
