import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import FooterNavigationBlock from "../components/FooterNavigationBlock"

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            LogoTwitter: file(relativePath: { eq: "logo-twitter.svg" }) {
                publicURL
            },
            LogoGithub: file(relativePath: { eq: "logo-github.svg" }) {
                publicURL
            },

        }
    `);

    const footerLinks = {
        product: [
            {
                title: "For Destinations",
                target: "/for-destinations"
            },
            {
                title: "For Vehicle Owners",
                target: "/for-vehicle-owners"
            },
            {
                title: "Featured destination",
                target: "/featured-destination"
            },
            {
                title: "How it works",
                target: "/how-it-works"
            },
            {
                title: "Pricing",
                target: "/pricing"
            },
        ],
        legal: [
            {
                title: "Privacy policy",
                target: "/privacy"
            },
            {
                title: "Terms of service",
                target: "/terms"
            },
            {
                title: "Cooke policy",
                target: "/cookie-policy"
            },
            {
                title: "Acceptable use policy",
                target: "/acceptable-use-policy"
            },
        ],
        help: [
            // {
            //     title: "Get Support",
            //     target: "/support"
            // },
            {
                title: "Contact Us",
                target: "/contact-us"
            },
        ]
    };

    return (
        <footer className="flex flex-col mx-4 mt-20 mb-10 lg:mx-auto lg:max-w-screen-lg text-relay-text">
            <div className="flex flex-col justify-between lg:flex-row">
                <div className="flex flex-col items-start mb-8 lg:flex-row lg:mb-0">
                    <div>
                        <h1 translate="no" className="flex items-center h-12 text-2xl font-extrabold">Relay</h1>
                        <p className="pb-4">An Anomaly Company</p>
                        <p>B474 AgriPark</p>
                        <p>Charles Sturt University</p>
                        <p>Tooma way</p>
                        <p>Wagga Wagga (NSW) 2650</p>
                        <p className="mb-8">Australia</p>
                        <p className="text-gray-500">ABN <b><a href="https://abr.business.gov.au/ABN/View?id=89107600975">89 107 600 975</a></b></p>
                        <p className="mt-10 text-sm text-gray-500">&copy; Anomaly Software Pty Ltd</p>
                    </div>
                </div>
                <nav className="flex flex-col text-gray-500 border-t-2 border-b-2 border-gray-300 divide-y-2 divide-gray-300 lg:flex-row lg:border-t-0 lg:border-b-0 lg:divide-y-0">
                    <FooterNavigationBlock heading="Product" links={footerLinks.product}/>
                    <FooterNavigationBlock heading="Legal"  links={footerLinks.legal}/>
                    <FooterNavigationBlock heading="Help &amp; Support"  links={footerLinks.help}/>
                </nav>
                <div className="flex mt-8 lg:mt-0">
                    <a href="https://twitter.com/relaybuzz">
                        <img src={data.LogoTwitter.publicURL} className="w-5" height={24} width={24} alt="Twitter Logomark"/>
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;