import React, { useState } from 'react'
import { Link } from 'gatsby'
// import useMediaQuery from '../hooks/useMediaQuery'

const Header = () => {

    const [accordionOpen, setAccordionOpen] = useState(false);
    // const breakpointCheck = useMediaQuery('(max-width: 768px)');

    const headerClasses = "flex flex-col items-center p-4 lg:justify-between lg:p-0 lg:flex-row lg:mx-auto lg:max-w-screen-xl";
    const headerMobileOpenClasses = "min-h-screen fixed top-0 z-50 bg-white w-full";
    
    return (
        <div className="w-full bg-white lg:my-5 bg-opacity-90">
            <header className={accordionOpen ? `${headerClasses} ${headerMobileOpenClasses}`:`${headerClasses}`}>
                <div className="flex flex-row justify-between w-full lg:w-auto">
                    <Link to="/">
                        <h1 translate="no" className="flex items-center h-12 text-2xl font-extrabold">Relay</h1>
                    </Link>
                    <button aria-label="Menu" className="lg:hidden" onClick={() => setAccordionOpen((o) => !o)}>
                        <svg className={`${!accordionOpen ? 'block' : 'hidden'} h-8`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        <svg className={`${accordionOpen ? 'block' : 'hidden'} h-8`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <nav className={`${accordionOpen ? 'block' : 'hidden'} w-full lg:w-auto lg:block`}>
                    <ul className="flex flex-col justify-between mt-8 text-xl ml-14 lg:text-base lg:mt-0 lg:text-black lg:text-center lg:ml-0 lg:flex-row lg:w-96">
                        <li className="mb-4 lg:mb-0">
                            <Link to="/for-destinations">
                                For Destinations
                                <p className="text-base text-gray-500 lg:hidden">Workflow for destinations.</p>
                            </Link>
                        </li>
                        <li className="mb-4 lg:mb-0">
                            <Link to="/for-vehicle-owners">
                                For Vehicle Owners
                                <p className="text-base text-gray-500 lg:hidden">Benefits for vehicle owners.</p>
                            </Link> 
                        </li>
                        <li className="mb-4 lg:mb-0">
                            <Link to="/pricing">
                                Pricing
                                <p className="text-base text-gray-500 lg:hidden">Learn about our pricing.</p>
                            </Link> 
                        </li>
                        <li className="mb-4 lg:mb-0 lg:hidden">
                            <a href="https://confirmsubscription.com/h/d/05B469E3572052F4">
                                Early access
                                <p className="text-base text-gray-500 lg:hidden">Leave your details with us.</p>
                            </a> 
                        </li>
                    </ul>
                </nav>
                <div className="hidden lg:block">
                    <a className="px-8 py-3 cursor-pointer " href="https://confirmsubscription.com/h/d/05B469E3572052F4">
                        Early access
                    </a>
                </div>
            </header>
        </div>
    );
};

export default Header;
